
.header {
    background-color:white;
    min-height: 80px;
    color:  #282c34;
    margin: 0;
    padding: 0;;
  
    .header-wrapper{
            .menu{ 

                display: none;
            }
            

    .mobile-menu{ 
        display: block;
        }
}
}

.row-box{

display: block;
flex-direction: row;
flex-wrap: wrap;
flex-wrap: wrap-reverse;
flex-flow: row wrap;
margin: 10px auto;

}

.col-1{ width: calc( 100% - 20px ); padding: 10px; text-align: left;  min-height: 150px;}
.col-2{ width: calc( 100% - 30px ); padding: 10px; text-align: left; min-height: 150px;}
.col-3{ width: calc( 100% - 24px ); padding: 10px; text-align: left; min-height: 150px;}
.col-4{ width: calc( 25% - 10px ); padding: 5px; text-align: left; min-height: 150px;}

.footer-row-box{

display: flex;
flex-direction: row;
flex-wrap: wrap;
flex-wrap: wrap-reverse;
flex-flow: row wrap;
margin: 10px auto;



.col-3{ width: calc( 33.3% - 24px ); padding: 10px; text-align: left; min-height: 120px;}

}
.slick-slide {

float: left;
height: 50%;
min-height: 1px;
display: inline-block;
position: relative;

.slide-text-left{
display: block;
text-align: left;
position : absolute;
bottom: 25px;
left : 50px;
height: 50%;
width: calc( 100% - 100px );
background-color: rgba(0,0,0,.4) ;
color: #222;

.left-text-content{
    display: block;
    text-align: left;
    padding: 20px;
    position : absolute;
    top: 50%;
    left: 0;
    width: 100%;            
    transform: translate(0, -50%);

    h1{ color: #ffd000; font-size: 22px; font-family: $bold-font;}
    p{color: #fff; font-size: 16px ; width: 90%; display: inline-block;}
}
}
.slide-text-right{display: block; text-align: left;
    position : absolute; bottom: 25px;
    right : 50px;
    height: 50%;
    width: calc( 100% - 100px );
    background-color: rgba(0,0,0,.4);
    color: #fff;

.right-text-content{
    display: block;
    text-align: left;
    padding: 20px;
    position : absolute;
    top: 50%;
    left: 0;
    width: calc( 100% - 40px );
    transform: translate(0, -50%);

    h1{ color: #ffd000; font-size: 22px; font-family: $bold-font; }
    p{color: #fff; font-size: 16px; width: 90%; display: inline-block;}

}
}

[dir="rtl"] & {
float: right;
}
img {
width: 100%;
height: 50vh;
object-fit: cover;
}
&.slick-loading img {
display: none;
}

display: none;

&.dragging img {
pointer-events: none;
}

.slick-initialized & {
display: block;
}

.slick-loading & {
visibility: hidden;
}

.slick-vertical & {
display: block;
height: auto;
border: 1px solid transparent;
}
}