$light-font: website_light_font;
$regular-font: website_font;
$bold-font: website_bold_font;


@font-face {
  font-family: $regular-font;
  src: url(fonts/DINPro-Medium.otf);
  
}

@font-face {
  font-family: $bold-font;
  src:  url(fonts/DINPro-Bold.otf);
}

@font-face {
  font-family: $light-font;
  src: url(fonts/DINPro-Light.otf);;
}

html{ overflow-y: scroll;}

.bold-text{ font-family: $bold-font;}


body {
  margin: 0;
  background: #f7f8f8;
  font-family: $light-font, Arial, Helvetica, sans-serif;
  min-height: 100%;
  padding: 0;
  font-size: 1rem;
  
}

html {
  overflow-y: scroll;
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 32px;
}

.wrapper{
    width: 100%;
    min-height: calc( 100vh + 5px );
    position: relative;
    margin: 0;
    padding: 0
}

a {
  cursor: pointer;
}

/* heading text styles */

h1 {
  text-transform: none;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  font-size:30px;
  position:relative; z-index: 3;
}

h2 {
  font-size: 25px;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  position:relative; z-index: 3;
}

h2 a {
  color: #06C;
  margin: 20px;
}

h2 a:hover {
  color: #06C;
  text-decoration: underline;
}

h3 {
	font-size: 22px;
  text-transform: uppercase;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  color: #222;
}

h4 {
	font-size: 19px;
  text-transform: capitalize;
  font-family: $light-font, Arial, Helvetica, sans-serif;
  color: #111;
}


.item{

    width: calc( 100% - 40px );
    display: block;
    margin: 20px;
    height: 320px;
    position: relative;
    background-color: #999;
    background: url(../img/work/sasseta.jpg) no-repeat top center;
    background-size: 100%;
    cursor: pointer;
    transition: background-size .5s ease 0.2s;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          

    .item-footer{
    
      background-color: #fafafa;;
      color: #666;
      width: calc( 100% - 20px );
      height: 100px;
      padding: 10px;
      position: absolute;
      bottom: 0;
    
      
  }

  &:hover{ background-size: 110%;}
}


.content-wrapper{width: calc( 100% - 20px ); max-width: 1200px; margin: 10px auto;

  p{padding: 5px;}

}

.row-label{

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap-reverse;
  flex-flow: row wrap;
  margin: 0px auto;
  border-top: 1px solid #ccc;

  .letter-label{font-family: $bold-font; font-size: 60px; width: 80px; height: 80px; line-height: 80px; text-align: center;}
  .desc{width: calc( 100% - 80px ) ; height: 80px; line-height: 80px; text-align: left;}
  
}

.row-box{

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap-reverse;
  flex-flow: row wrap;
  margin: 10px auto;

}

.col-1{ width: calc( 100% - 20px ); padding: 10px; text-align: left;  min-height: 150px;}
.col-2{ width: calc( 50% - 30px ); padding: 10px; text-align: left; min-height: 150px;}
.col-3{ width: calc( 33.3% - 24px ); padding: 10px; text-align: left; min-height: 150px;}
.col-4{ width: calc( 25% - 10px ); padding: 5px; text-align: left; min-height: 150px;}

.footer-row-box{

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap-reverse;
  flex-flow: row wrap;
  margin: 10px auto;
  
  
  
  .col-3{ width: calc( 33.3% - 24px ); padding: 10px; text-align: left; min-height: 120px;}
  
  }
  
.service-icon{  width: 50px; 
                height: 50px;
                line-height: 50px;
                border-radius: 100%;
                display: inline-block;
                background-color: #ffb910;
                color : #333;
                padding: 8px;
               }

               .home-btn{
                  display: inline-block;
                  background-color: #ffb910;
                  color:  #000;
                   padding: 16px;
                   text-decoration: none;
               &:hover{background-color: #ca8e01; color: #000;
               }
                  }

  .image-art{ height: 240px; width: 100%; background-color: #ffb910;}

