
     .popup {
        z-index: 2000;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          visibility: hidden;
          transform: scale(1.1);
          transition: visibility 0s ease 0.07s, opacity 0.03s 0s, transform 0.05s;
          z-index: 2500;
      }

      .show-popup {
        opacity: 1;
        visibility: visible;
        transform: scale(1.0);
        transition: visibility 0s linear 0s, opacity 0.07s 0s, transform 0.1s;
    }
    
      .popup-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          padding: 10px;
          min-width: 220px;
          max-width: 720px;
          width: calc( 100% - 20px);
          border-radius: 1rem;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
      }
      
      .close-button {
          float: right;
          width: 1.5rem;
          line-height: 1.5rem;
          text-align: center;
          cursor: pointer;
          border-radius: 100%;
          background-color: lightgray;
      }
      .close-button:hover {
          background-color: darkgray;
      }
      
   
      
.side-menu {
    position: fixed;
    left: 0;
    top: -2px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.5);
    transition: visibility .55s ease-in, opacity 0.55s ease, transform 0.25s ease-in;
    z-index: 2500;
}

.side-menu-show {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2500;
    left: 0;
    top:  0px;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility .25s ease-out, opacity 0.15s ease-in, transform 0.25s ease-in;

}


.side-menu-content {

    position: fixed;
    height: 100hv;
    top: 0;
    right: -200px;
    background-color: white;
    width: 200px;
    visibility:hidden;
    opacity: 0;
    transition: visibility 1.5s linear, opacity .5s ease, transform 0.25s, right 1s ease;
  
}

.side-menu-content-show {

    position: fixed;
    height: 100%;
    top: 0;
    right: 0px;
    background-color: white;
    width: 200px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease;
  
  }

.side-menu-brand {

    float: left;
    width: 100px ;
    line-height: 1.5rem;
    text-align: left;
    margin: 10px;
    text-transform: uppercase;
    content: 'IBIN';
    color:#999;
    letter-spacing: 4px;
    font-family : $bold_font;
}

.side-menu-close-button {
  
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;
    margin: 10px;
  
    &:hover { background-color: darkgray; }
  
  }

.side-menu-controlheader{ 
  
    text-align  : center;
    font-size   : 24px;
    text-align  : center;
    width: 100%;
    
    ul{
        margin: 0;
        padding: 0;
        width: 100% ;
       clear: both;
       text-indent: none;
       line-height: 40px;
       text-align: left;
       text-transform: uppercase;
       font-size: 14px;
     }

    li{ clear:both; text-indent: none; counter-increment: section; list-style-type: none; border-bottom:1px solid rgba(165,175, 186 ,.5); transition: background .45s ease;
    
    a::before{ width: 30px; height: 30px; margin-right: 6px; margin-left: 6px; content: ''; font-size:9px; color: #a5afba; line-height: 30px; display: inline-block; }
    
    a{ text-decoration:none; color: #221f1f; display:block; width: 100%; transition: color .5s ease;
        
      &:hover{ text-decoration:none; color: #fff; display:block; width: 100%;}
      
    }
    &:hover{ text-indent: none; list-style-type: none; border-bottom:1px solid rgba(165,175, 186 ,.5); background: #221f1f; color:#FFF }
 
    }

   
  }