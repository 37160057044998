@import './body';
@import './header';
@import './footer';

@import './popup';

@import './dropdown';

@import './slick';
@import './slick-theme';
@import './slider';
@import './form';



/* 
    media 1921  {

         .slick-slide { height: 100%; }
     
        }
    media 1920 - 1025 {     .slick-slide { height: 100%; }
     }
    media 1024 - 601 {     .slick-slide { height: 60%; }
     }
    media | 600 - 481 {     .slick-slide { height: 50%; }
     }
    480 {     .slick-slide { height: 30%; }
     }
    
       */
       @media screen and (max-width: 620px) {
    
        @import './mobile';

    }


.light-mode{


}

.dark-mode{

  
}
