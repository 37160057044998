


.header {
  background-color:white;
  min-height: 80px;
  color:  #282c34;
  margin: 0;
  padding: 0;;

  .header-wrapper{
    
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    max-width: 1200px;
    width: 100%;
    position:relative;
    
    .logo {
    
    width: 240px;
    height: 90px;
    margin-top: 5px;
    background: url(../img/logo.svg) no-repeat center 0;
    background-size: 94%;
    transition: background-size 0.1s ease-out;
    cursor: pointer;
    &:hover {
      background-size: 100%;
      transition: background-size 0.1s ease-in;
    }
  
  }
  

  .menu{ 
          display: flex;
          flex-direction: row;
          justify-content: right;
          width: calc( 100% - 190px );
          margin-top: 10px;
          text-transform: capitalize;
          font-size: 13px;

            a{ display: block; margin-top: 20px; margin-right: 5px; width: 70px; height: 30px; line-height: 30px; text-align: center; color: #222; text-decoration: none;
      
            &:hover{ border-bottom: 4px solid #48a200;}
            
            }
    
        }
      

      .mobile-menu{ 

          display: none;
          width: 50px ;
          width: calc( 100% - 190px );
          text-transform: capitalize;
          font-size: 13px;
          justify-content: right;
          
          a{  float: right; background: #edfae2; font-size: 24px; font-weight:  bold; justify-content: right; display: block; margin-top: 10px; margin-right: 10px; width: 50px; height: 50px; line-height: 50px; text-align: center; color: #48a200; text-decoration: none;
      
            &:hover{ background: #e3f5d4; font-size: 28px; transition: font-size 0.2s ease;}
            
            }
  
      }
    }
  }


.side-menu{

  a{ color: #222;}

}


.side-popup{

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba( 0 , 0, 0 , .5);
  width: 100%;
  height: 100%;
  z-index: 30;
  display: none;

  .side-menu{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 280px;
  height: 100%;
  z-index: 31;
    
    .brand-box{ display: block; }

    .menu{
      
      ul{display: block; list-style: none;}
      li{ display: block;

        a{display: block; padding: 8px; background-color: #fff;
          &:hover{ background-color: #600; color: #fff}
        }
      }
    
    }

  }
}
