/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {

    float: left;
    height: 100%;
    min-height: 1px;
    display: inline-block;
    position: relative;
    
    .slide-text-left{
        display: block;
        text-align: left;
        position : absolute;
        bottom: 25px;
        left : 50px;
        height: 50%;
        width: 50%;
        background-color: rgba(0,0,0,.4) ;
        color: #222;
    
        .left-text-content{
            display: block;
            text-align: left;
            padding: 20px;
            position : absolute;
            top: 50%;
            left: 0;
            width: 100%;            
            transform: translate(0, -50%);
        
            h1{ color: #ffd000; font-size: 40px; font-family: $bold-font;}
            p{color: #fff; font-size: 16px ; width: 90%; display: inline-block;}
        }
    }
        .slide-text-right{display: block; text-align: left;
            position : absolute; bottom: 25px;
            right : 50px;
            height: 50%;
            width: 50%;
            background-color: rgba(0,0,0,.4);
            color: #fff;
    
        .right-text-content{
            display: block;
            text-align: left;
            padding: 20px;
            position : absolute;
            top: 50%;
            left: 0;
            width: calc( 100% - 40px );
            transform: translate(0, -50%);
        
            h1{ color: #ffd000; font-size: 40px; font-family: $bold-font; }
            p{color: #fff; font-size: 16px; width: 90%; display: inline-block;}
        
        }
    }
    
    [dir="rtl"] & {
        float: right;
    }
    img {
        width: 100%;
        height: 70vh;
        object-fit: cover;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

