@charset "UTF-8";
@font-face {
  font-family: website_font;
  src: url(fonts/DINPro-Medium.otf);
}
@font-face {
  font-family: website_bold_font;
  src: url(fonts/DINPro-Bold.otf);
}
@font-face {
  font-family: website_light_font;
  src: url(fonts/DINPro-Light.otf);
}
html {
  overflow-y: scroll;
}

.bold-text {
  font-family: website_bold_font;
}

body {
  margin: 0;
  background: #f7f8f8;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  min-height: 100%;
  padding: 0;
  font-size: 1rem;
}

html {
  overflow-y: scroll;
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 32px;
}

.wrapper {
  width: 100%;
  min-height: calc(100vh + 5px);
  position: relative;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
}

/* heading text styles */
h1 {
  text-transform: none;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  font-size: 30px;
  position: relative;
  z-index: 3;
}

h2 {
  font-size: 25px;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  position: relative;
  z-index: 3;
}

h2 a {
  color: #06C;
  margin: 20px;
}

h2 a:hover {
  color: #06C;
  text-decoration: underline;
}

h3 {
  font-size: 22px;
  text-transform: uppercase;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  color: #222;
}

h4 {
  font-size: 19px;
  text-transform: capitalize;
  font-family: website_light_font, Arial, Helvetica, sans-serif;
  color: #111;
}

.item {
  width: calc(100% - 40px);
  display: block;
  margin: 20px;
  height: 320px;
  position: relative;
  background-color: #999;
  background: url(../img/work/sasseta.jpg) no-repeat top center;
  background-size: 100%;
  cursor: pointer;
  transition: background-size 0.5s ease 0.2s;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.item .item-footer {
  background-color: #fafafa;
  color: #666;
  width: calc(100% - 20px);
  height: 100px;
  padding: 10px;
  position: absolute;
  bottom: 0;
}
.item:hover {
  background-size: 110%;
}

.content-wrapper {
  width: calc(100% - 20px);
  max-width: 1200px;
  margin: 10px auto;
}
.content-wrapper p {
  padding: 5px;
}

.row-label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap-reverse;
  flex-flow: row wrap;
  margin: 0px auto;
  border-top: 1px solid #ccc;
}
.row-label .letter-label {
  font-family: website_bold_font;
  font-size: 60px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.row-label .desc {
  width: calc(100% - 80px);
  height: 80px;
  line-height: 80px;
  text-align: left;
}

.row-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap-reverse;
  flex-flow: row wrap;
  margin: 10px auto;
}

.col-1 {
  width: calc(100% - 20px);
  padding: 10px;
  text-align: left;
  min-height: 150px;
}

.col-2 {
  width: calc(50% - 30px);
  padding: 10px;
  text-align: left;
  min-height: 150px;
}

.col-3 {
  width: calc(33.3% - 24px);
  padding: 10px;
  text-align: left;
  min-height: 150px;
}

.col-4 {
  width: calc(25% - 10px);
  padding: 5px;
  text-align: left;
  min-height: 150px;
}

.footer-row-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: wrap-reverse;
  flex-flow: row wrap;
  margin: 10px auto;
}
.footer-row-box .col-3 {
  width: calc(33.3% - 24px);
  padding: 10px;
  text-align: left;
  min-height: 120px;
}

.service-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
  display: inline-block;
  background-color: #ffb910;
  color: #333;
  padding: 8px;
}

.home-btn {
  display: inline-block;
  background-color: #ffb910;
  color: #000;
  padding: 16px;
  text-decoration: none;
}
.home-btn:hover {
  background-color: #ca8e01;
  color: #000;
}

.image-art {
  height: 240px;
  width: 100%;
  background-color: #ffb910;
}

.header {
  background-color: white;
  min-height: 80px;
  color: #282c34;
  margin: 0;
  padding: 0;
}
.header .header-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  max-width: 1200px;
  width: 100%;
  position: relative;
}
.header .header-wrapper .logo {
  width: 240px;
  height: 90px;
  margin-top: 5px;
  background: url(../img/logo.svg) no-repeat center 0;
  background-size: 94%;
  transition: background-size 0.1s ease-out;
  cursor: pointer;
}
.header .header-wrapper .logo:hover {
  background-size: 100%;
  transition: background-size 0.1s ease-in;
}
.header .header-wrapper .menu {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: calc(100% - 190px);
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 13px;
}
.header .header-wrapper .menu a {
  display: block;
  margin-top: 20px;
  margin-right: 5px;
  width: 70px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #222;
  text-decoration: none;
}
.header .header-wrapper .menu a:hover {
  border-bottom: 4px solid #48a200;
}
.header .header-wrapper .mobile-menu {
  display: none;
  width: 50px;
  width: calc(100% - 190px);
  text-transform: capitalize;
  font-size: 13px;
  justify-content: right;
}
.header .header-wrapper .mobile-menu a {
  float: right;
  background: #edfae2;
  font-size: 24px;
  font-weight: bold;
  justify-content: right;
  display: block;
  margin-top: 10px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #48a200;
  text-decoration: none;
}
.header .header-wrapper .mobile-menu a:hover {
  background: #e3f5d4;
  font-size: 28px;
  transition: font-size 0.2s ease;
}

.side-menu a {
  color: #222;
}

.side-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 30;
  display: none;
}
.side-popup .side-menu {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 280px;
  height: 100%;
  z-index: 31;
}
.side-popup .side-menu .brand-box {
  display: block;
}
.side-popup .side-menu .menu ul {
  display: block;
  list-style: none;
}
.side-popup .side-menu .menu li {
  display: block;
}
.side-popup .side-menu .menu li a {
  display: block;
  padding: 8px;
  background-color: #fff;
}
.side-popup .side-menu .menu li a:hover {
  background-color: #600;
  color: #fff;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: -10px;
  left: 0px;
  margin: 0;
  margin-bottom: -5px;
  background: #48a200;
  min-height: 150px;
  color: #fff;
}
.footer ul {
  list-style: none;
  margin: 0;
}
.footer ul li {
  display: block;
  cursor: pointer;
  margin: 0;
}
.footer ul li a {
  display: block;
  color: #fff;
  text-decoration: none;
}
.footer ul li a:hover {
  color: #ffec41;
}

.spacer {
  min-height: 300px;
  margin-top: 50px;
}

.popup {
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s ease 0.07s, opacity 0.03s 0s, transform 0.05s;
  z-index: 2500;
}

.show-popup {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.07s 0s, transform 0.1s;
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  min-width: 220px;
  max-width: 720px;
  width: calc(100% - 20px);
  border-radius: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
}

.close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  background-color: lightgray;
}

.close-button:hover {
  background-color: darkgray;
}

.side-menu {
  position: fixed;
  left: 0;
  top: -2px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.5);
  transition: visibility 0.55s ease-in, opacity 0.55s ease, transform 0.25s ease-in;
  z-index: 2500;
}

.side-menu-show {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2500;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0.25s ease-out, opacity 0.15s ease-in, transform 0.25s ease-in;
}

.side-menu-content {
  position: fixed;
  height: 100hv;
  top: 0;
  right: -200px;
  background-color: white;
  width: 200px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1.5s linear, opacity 0.5s ease, transform 0.25s, right 1s ease;
}

.side-menu-content-show {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0px;
  background-color: white;
  width: 200px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease;
}

.side-menu-brand {
  float: left;
  width: 100px;
  line-height: 1.5rem;
  text-align: left;
  margin: 10px;
  text-transform: uppercase;
  content: "IBIN";
  color: #999;
  letter-spacing: 4px;
  font-family: website_bold_font;
}

.side-menu-close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
  margin: 10px;
}
.side-menu-close-button:hover {
  background-color: darkgray;
}

.side-menu-controlheader {
  text-align: center;
  font-size: 24px;
  text-align: center;
  width: 100%;
}
.side-menu-controlheader ul {
  margin: 0;
  padding: 0;
  width: 100%;
  clear: both;
  text-indent: none;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}
.side-menu-controlheader li {
  clear: both;
  text-indent: none;
  counter-increment: section;
  list-style-type: none;
  border-bottom: 1px solid rgba(165, 175, 186, 0.5);
  transition: background 0.45s ease;
}
.side-menu-controlheader li a::before {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  margin-left: 6px;
  content: "";
  font-size: 9px;
  color: #a5afba;
  line-height: 30px;
  display: inline-block;
}
.side-menu-controlheader li a {
  text-decoration: none;
  color: #221f1f;
  display: block;
  width: 100%;
  transition: color 0.5s ease;
}
.side-menu-controlheader li a:hover {
  text-decoration: none;
  color: #fff;
  display: block;
  width: 100%;
}
.side-menu-controlheader li:hover {
  text-indent: none;
  list-style-type: none;
  border-bottom: 1px solid rgba(165, 175, 186, 0.5);
  background: #221f1f;
  color: #FFF;
}

.dropbtn {
  text-decoration: none;
  color: #FFF !important;
  background: #48a200;
  margin-top: 10px;
  margin-right: 10px;
  padding: 16px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  min-width: 160px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  /* Show the dropdown menu on hover */
  /* Change the background color of the dropdown button when the dropdown content is shown */
}
.dropdown a {
  width: 100% !important;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  background: #367900;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 30;
  /* Links inside the dropdown */
}
.dropdown-content a {
  width: calc(100% - 20px) !important;
  text-align: left !important;
  color: black;
  padding: 10px;
  text-decoration: none !important;
  display: block;
  border: 0 !important;
  margin: 0 !important;
  /* Change color of dropdown links on hover */
}
.dropdown-content a:hover {
  background-color: #daebe2;
  text-decoration: none !important;
  border: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: inline-block;
  position: relative;
  display: none;
}
.slick-slide .slide-text-left {
  display: block;
  text-align: left;
  position: absolute;
  bottom: 25px;
  left: 50px;
  height: 50%;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #222;
}
.slick-slide .slide-text-left .left-text-content {
  display: block;
  text-align: left;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translate(0, -50%);
}
.slick-slide .slide-text-left .left-text-content h1 {
  color: #ffd000;
  font-size: 40px;
  font-family: website_bold_font;
}
.slick-slide .slide-text-left .left-text-content p {
  color: #fff;
  font-size: 16px;
  width: 90%;
  display: inline-block;
}
.slick-slide .slide-text-right {
  display: block;
  text-align: left;
  position: absolute;
  bottom: 25px;
  right: 50px;
  height: 50%;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}
.slick-slide .slide-text-right .right-text-content {
  display: block;
  text-align: left;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(100% - 40px);
  transform: translate(0, -50%);
}
.slick-slide .slide-text-right .right-text-content h1 {
  color: #ffd000;
  font-size: 40px;
  font-family: website_bold_font;
}
.slick-slide .slide-text-right .right-text-content p {
  color: #fff;
  font-size: 16px;
  width: 90%;
  display: inline-block;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  width: 100%;
  height: 70vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 50;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #eee;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: 25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 25px;
}
[dir=rtl] .slick-next {
  left: 25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slider {
  background: rgb(206, 209, 208) url(../img/slides/slide_1.jpg) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 480px;
}

.public-form {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: 80px auto;
}
.public-form .form-row {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
.public-form .form-row label {
  display: block;
  width: 25%;
  font-weight: bold;
}
.public-form .form-row input {
  display: block;
  width: 75%;
}
.public-form .form-row textarea {
  display: block;
  width: 75%;
  min-height: 100px;
}
.public-form .form-row button {
  min-width: 120px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  background-color: #48a200;
  color: #fff;
  text-align: center;
}
.public-form button {
  margin-top: 12px;
  min-width: 120px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  background-color: #48a200;
  color: #fff;
  text-align: center;
}
.public-form button:hover {
  background-color: #367900;
}

.map-btn {
  display: block;
  width: 100%;
  min-height: 250px;
  background-color: antiquewhite;
}

.contact-info .contact-row {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
.contact-info .contact-row .label {
  display: block;
  width: 25%;
  font-weight: bold;
}
.contact-info .contact-row .details {
  display: block;
  width: 75%;
}

/* 
    media 1921  {

         .slick-slide { height: 100%; }

        }
    media 1920 - 1025 {     .slick-slide { height: 100%; }
     }
    media 1024 - 601 {     .slick-slide { height: 60%; }
     }
    media | 600 - 481 {     .slick-slide { height: 50%; }
     }
    480 {     .slick-slide { height: 30%; }
     }

       */
@media screen and (max-width: 620px) {
  .header {
    background-color: white;
    min-height: 80px;
    color: #282c34;
    margin: 0;
    padding: 0;
  }
  .header .header-wrapper .menu {
    display: none;
  }
  .header .header-wrapper .mobile-menu {
    display: block;
  }
  .row-box {
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: wrap-reverse;
    flex-flow: row wrap;
    margin: 10px auto;
  }
  .col-1 {
    width: calc(100% - 20px);
    padding: 10px;
    text-align: left;
    min-height: 150px;
  }
  .col-2 {
    width: calc(100% - 30px);
    padding: 10px;
    text-align: left;
    min-height: 150px;
  }
  .col-3 {
    width: calc(100% - 24px);
    padding: 10px;
    text-align: left;
    min-height: 150px;
  }
  .col-4 {
    width: calc(25% - 10px);
    padding: 5px;
    text-align: left;
    min-height: 150px;
  }
  .footer-row-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: wrap-reverse;
    flex-flow: row wrap;
    margin: 10px auto;
  }
  .footer-row-box .col-3 {
    width: calc(33.3% - 24px);
    padding: 10px;
    text-align: left;
    min-height: 120px;
  }
  .slick-slide {
    float: left;
    height: 50%;
    min-height: 1px;
    display: inline-block;
    position: relative;
    display: none;
  }
  .slick-slide .slide-text-left {
    display: block;
    text-align: left;
    position: absolute;
    bottom: 25px;
    left: 50px;
    height: 50%;
    width: calc(100% - 100px);
    background-color: rgba(0, 0, 0, 0.4);
    color: #222;
  }
  .slick-slide .slide-text-left .left-text-content {
    display: block;
    text-align: left;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
  }
  .slick-slide .slide-text-left .left-text-content h1 {
    color: #ffd000;
    font-size: 22px;
    font-family: website_bold_font;
  }
  .slick-slide .slide-text-left .left-text-content p {
    color: #fff;
    font-size: 16px;
    width: 90%;
    display: inline-block;
  }
  .slick-slide .slide-text-right {
    display: block;
    text-align: left;
    position: absolute;
    bottom: 25px;
    right: 50px;
    height: 50%;
    width: calc(100% - 100px);
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .slick-slide .slide-text-right .right-text-content {
    display: block;
    text-align: left;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(100% - 40px);
    transform: translate(0, -50%);
  }
  .slick-slide .slide-text-right .right-text-content h1 {
    color: #ffd000;
    font-size: 22px;
    font-family: website_bold_font;
  }
  .slick-slide .slide-text-right .right-text-content p {
    color: #fff;
    font-size: 16px;
    width: 90%;
    display: inline-block;
  }
  [dir=rtl] .slick-slide {
    float: right;
  }
  .slick-slide img {
    width: 100%;
    height: 50vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}/*# sourceMappingURL=index.css.map */