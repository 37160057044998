

 .public-form{

    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.2);
    max-width: 600px;
    margin: 80px auto;

        .form-row{
            display: flex;
            flex-direction: row;
            margin-top: 12px; ;
            
            label{ display: block; width: 25%; font-weight: bold;}
    
            input{display: block; width: 75%;}
            textarea{display: block; width: 75%; min-height: 100px;}
            
            button{ min-width: 120px; border: 0; cursor: pointer; display: inline-block; ; padding: 10px; background-color: $dropbtnBackgrounColor;  color: #fff; text-align: center;}
        
        }
    
            button{  margin-top: 12px; ;min-width: 120px; border: 0; cursor: pointer; display: inline-block; ; padding: 10px; background-color: $dropbtnBackgrounColor; color: #fff; text-align: center;
                    &:hover{ background-color: $dropbtnBackgroundHoverColor;}
                }
        
    }

    .map-btn{ display: block; width: 100%; min-height: 250px; background-color: antiquewhite;}

    .contact-info{
       
        .contact-row{
            display: flex;
            flex-direction: row;
            margin-top: 12px; ;
            
            .label{ display: block; width: 25%; font-weight: bold;}
             .details{display: block; width: 75%;}

        }
    }