


.footer {
  width: 100%;
  position: absolute;
  bottom: -10px;
  left: 0px;
  margin: 0;
  margin-bottom: -5px;
  background: #48a200;
  min-height: 150px;
  color: #fff;

  ul {
    list-style: none;
    margin: 0;
    li {
        display: block;
        cursor: pointer;
        margin: 0;
        
        a{
      
          display: block;
          color: #fff;
          text-decoration: none;

    
          &:hover{ color: #ffec41; }
    
      }
    }
  }
}
  
.spacer{

  min-height: 300px;
  margin-top: 50px;
  
}
