
$dropbtnBackgrounColor: #48a200;
$dropbtnBackgroundHoverColor : #367900;


.dropbtn {
    text-decoration:none;
    color:#FFF !important;
    background: $dropbtnBackgrounColor;
    margin-top: 10px;
    margin-right: 10px;
    padding: 16px;
    border: none;
    cursor: pointer;
    font-size:16px;
    min-width: 160px;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    
    a{ width: 100% !important;}

      /* Show the dropdown menu on hover */
    &:hover .dropdown-content {
      display: block;
    }
    
    /* Change the background color of the dropdown button when the dropdown content is shown */
    &:hover .dropbtn {
      
      background: $dropbtnBackgroundHoverColor;

    }
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 30;
    
  /* Links inside the dropdown */
   a {
    width: calc( 100% - 20px ) !important;
    text-align:left !important;
    color: black;
    padding: 10px;
    text-decoration: none !important;
    display: block;
    border: 0 !important;
    margin: 0 !important;
  
    /* Change color of dropdown links on hover */
  &:hover {
      
    background-color: #daebe2;
    text-decoration: none !important;
    border: 0;
  
  }

}}
  
  
  

